import PropTypes from "prop-types"
import React, { useState } from "react"
import { useThemeUI } from "theme-ui"
import { Range, getTrackBackground } from "react-range"

import Box from "./box"
import Stack from "./stack"
import Text from "./text"

const RangeSlider = ({
  id,
  step,
  min,
  max,
  onChange,
  disabled,
  initialValue,
  renderOutput,
  ...restProps
}) => {
  const context = useThemeUI()
  const { theme } = context
  const [values, setValues] = useState([initialValue])

  const onRangeChanged = ({ changedValues }) => {
    setValues(changedValues)
    // just report the first slider currently
    if (changedValues[0] !== values[0]) {
      onChange({ value: changedValues[0] })
    }
  }

  return (
    <Stack space={3}>
      <Range
        step={step}
        min={min}
        max={max}
        id={id}
        values={values}
        onChange={(changedValues) => onRangeChanged({ changedValues })}
        renderTrack={({ props, children }) => (
          <Box
            {...props}
            sx={{
              height: "2px",
              width: "100%",
              // backgroundColor: "border",
              background: getTrackBackground({
                values: values,
                colors: [theme.colors.primary, theme.colors.border],
                min: min,
                max: max,
              }),
            }}
          >
            {children}
          </Box>
        )}
        renderThumb={({ props, isDragged }) => (
          <Box
            {...props}
            sx={{
              height: 6,
              width: 6,
              borderRadius: "md",
              borderWidth: 2,
              borderColor: "contrast",
              boxShadow: "lg",
              bg: "background",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              ":hover, :focus": {
                bg: "muted",
              },
              ":focus": {
                //outline: `2px dashed ${theme.colors.text}`,
                outline: 0,
                boxShadow: "0 0 0 3px rgba(247,166,27, 0.5)",
              },
            }}
          >
            <Box
              sx={{
                width: "2px",
                height: 2,
                bg: isDragged ? "primary" : "border",
              }}
            ></Box>
          </Box>
        )}
        renderMark={({ props, index }) => (
          <Box
            {...props}
            sx={{
              height: 2,
              width: "2px",
              backgroundColor: index * step < values[0] ? "primary" : "border",
              /* expanding the click area */
              "::after": {
                content: `""`,
                position: "absolute",
                left: "50%",
                top: "50%",
                width: 6,
                height: 6,
                transform: "translate(-50%, -50%)",
                background: "transparent",
              },
            }}
          />
        )}
        disabled={disabled}
        {...restProps}
      />
      {renderOutput && (
        <output htmlFor={id}>
          <Text sans align="center">
            {values[0]}
          </Text>
        </output>
      )}
    </Stack>
  )
}

RangeSlider.propTypes = {
  step: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  renderOutput: PropTypes.bool,
  initialValue: PropTypes.number,
}

RangeSlider.defaultProps = {
  step: 1,
  min: 1,
  max: 5,
  disabled: false,
  renderOutput: false,
  initialValue: 1,
}

export default RangeSlider
