import React from "react"
import PropTypes from "prop-types"

import Columns from "../../../../components/columns"
import Column from "../../../../components/column"
import Box from "../../../../components/box"
import Stack from "../../../../components/stack"
import Heading from "../../../../components/heading"
import Paragraph from "../../../../components/paragraph"
import Paper from "../../../../components/paper"

const LetterSidebar = ({ sidebar, title, before, main }) => {
  return (
    <Columns collapse={[true, true, false]} space={[6, 6, 12]}>
      <Column width={["100%", "100%", "7/12"]}>
        <Stack space={6}>
          {before || null}
          <Paper p={[6, 8, 8, 10]}>
            <Stack space={6}>
              {title && (
                <Heading level={6} as="p">
                  {title}
                </Heading>
              )}
              <Paragraph as="div" size={[2, 3]}>
                {main}
              </Paragraph>
            </Stack>
          </Paper>
        </Stack>
      </Column>
      <Column width={["100%", "100%", "5/12"]}>
        <Box sx={{ position: ["static", null, null, "sticky"], top: 12 }}>
          {sidebar}
        </Box>
      </Column>
    </Columns>
  )
}

LetterSidebar.propTypes = {
  title: PropTypes.string,
  sidebar: PropTypes.node,
  main: PropTypes.node,
}

export default LetterSidebar
